@font-face {
  font-family: 'HalvarBreit';
  src: url(./assets/fonts/HalvarBreit-XBd.ttf) format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HalvarBreit';
  src: url(./assets/fonts/HalvarBreit-Bd.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HalvarBreit';
  src: url(./assets/fonts/HalvarBreit-Md.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HalvarBreit';
  src: url(./assets/fonts/HalvarBreit-Rg.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }